import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import Container from 'src/components/grid/Container/Container';
import Flex from 'src/components/grid/Flex/Flex';
import Row from 'src/components/grid/Row/Row';
import Section from 'src/components/grid/Section/Section';
import ProjectCard from 'src/components/molecules/ProjectCard/ProjectCard';
import ArchiveHero from 'src/components/organisms/ArchiveHero/ArchiveHero';
import CoreLayout from 'src/layouts/CoreLayout/CoreLayout';
import { sanitizeEncodedChar } from 'src/utils';
import { IMenuOpen, IPageContext } from 'src/utils/interfaces';
import styles from './ProjectTaxonomyTemplate.module.scss';
import Pagination from 'src/components/organisms/Pagination/Pagination';

export class UnconnectedProjectTaxonomyTemplate extends PureComponent<IPageContext> {
  render() {
    const { pageContext = {}, location } = this.props;
    const { items = [] } = pageContext;
    const pageTitle = sanitizeEncodedChar(pageContext.title);

    if (typeof window !== 'undefined') {
      // console.log('[ProjectTaxonomy]', items);
    }

    return (
      <>
        <CoreLayout
          location={location}
          title={pageTitle}
          description={pageContext.yoast_meta && pageContext.yoast_meta.yoast_wpseo_metadesc}
          data-test="template-taxonomy"
          className={
            classNames(
              [
                styles.taxonomy,
                {
                  [`taxonomy__${pageContext.slug && pageContext.slug.replace('_', '-')}`]: pageContext.slug,
                },
              ]
            )
          }
        >

          <ArchiveHero
            pageContext={pageContext}
            location={location}
          />

          <Section>
            <Container fluid={false}>
              <Row>
                {items && items.map((project: any) => {
                  if (project.slug !== '____dumbypost') {
                    return (
                      <Flex
                        key={project.id}
                        colXl={4}
                        colLg={6}
                        colMd={6}
                        className={styles.taxonomyProjectWrapper}
                      >
                        <ProjectCard
                          className={classNames(styles.taxonomyProject, `theme--tint-beta`)}
                          shadow={true}
                          project={project}
                        />
                      </Flex>
                    );
                  }

                  return null;
                })}
              </Row>
            </Container>
          </Section>

          <Pagination location={location} totalPages={pageContext.noOfPages} currentPage={pageContext.currentPage}/>

        </CoreLayout>
      </>
    );
  }
}

/* istanbul ignore next */
const mapStateToProps = ({ core: { isMenuOpen } }: IMenuOpen) => ({
  isMenuOpen,
});

export default connect(
  mapStateToProps,
  null,
)(UnconnectedProjectTaxonomyTemplate);
